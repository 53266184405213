.footer {
    background-color: $color-dark;
    padding-top: 80px;
    position: relative;

    &::before {
        content: '';
        display: block;
        @include absolute($top: 50px, $left: calc(50% + 225px));
        @include size(412px, 400px);
        background-image: url($urlSpriteImage + "theme-decos/france.svg");
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        
    }

    &__container {
        @include flex($wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
        gap: 35px 50px;
    }


    &__infos {
        @include flex($direction: column, $justifyContent: center,$alignItems: flex-start);
        gap: 10px;
        flex: none;
        color: $color-white;
        width: 350px;
    }

    &__title {
        color: $color-white;
        @include font-size(22);
        font-weight: $font-weight-bold;
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: {
           property: text-decoration-color;
           duration: $duration;
           timing-function: $timing;
        }
        
        &:hover, &:focus {
           text-decoration-color: $color-white;
           color: $color-white;
        }
    }

    &__btns {
        width: 100%;
        @include flex($direction: row);
        gap: 150px;
    }

    &__btn {
        margin: 0;
        color: $color-dark;
        font-size: $font-size--text;
        background-color: $color-white;
        border-color: $color-white;
        height: 50px;
        width: 250px;

        svg {
            @include size(20px);
            fill: $color-dark;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-main;
        }


        &--secondary {
            margin: 0 auto 0 0;
            background-color: $color-bg--transparent;
            color: $color-white;
            font-size: $font-size--text;
            border: $border-style 2px $color-second;
            height: 50px;
            width: 250px;

            svg {
                @include size(20px);
            }

            &:hover, &:focus {
                background-color: $color-second;
                border-color: $color-second;
            }
        }
    }

    &__menu {
        padding: 80px 0 60px;
        background-color: $color-dark;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
        }

        &__link {
            color: $color-white;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: {
               property: color, text-decoration-color;
               duration: $duration;
               timing-function: $timing;
            }
            
            &:hover, &:focus {
               text-decoration-color: $color-white;
               color: $color-white;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &::before {
            left: calc(50% + 303px);
        }

        &__container {
            gap: 35px 100px;
        }

        &__infos {
            width: 300px;
        }

        &__title {
            font-size: toRem(22);
        }

        &__text {
            font-size: $font-size--text-small;
        }

        &__phone {
            font-size: $font-size--text-small;
        }

        &__hours {
            font-size: $font-size--text-small;
        }

        &__menu {
            &__link {
                
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        &::before {
            display: none;
        }

        &__container {
            gap: 35px;
            justify-content: space-between;
        }

        &__infos {
            max-width: 300px;
            width: 100%;
        }

        &__btns {
            gap: 0;
            justify-content: space-between;
        }

        &__btn--secondary {
            margin-right: 50px;
        }

        &__menu {
            padding-bottom: 115px;

            &__list {
                justify-content: flex-start;
                row-gap: 20px;
            }

            &__item {
                padding: 0 20px 0 0;
                position: relative;
                margin-right: 20px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {
        &__infos {
            flex: none;
            width: 100%;
        }

        &__btns {
            flex-wrap: wrap;
            gap: 20px;
        }
    }

}
