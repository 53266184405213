@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.accordion {
    position: relative;
    margin-top: 30px;

    &__title {
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        outline: $color-dark;

        &--question {
            min-height: $accord-min-height;
            padding: $accord-min-padding;
            font-family: $accord-ffamily;
            font-weight: $accord-fweight;
            font-size: $accord-fsize;
            line-height: $accord-line-height;
            color: $accord-color;
            background-color: $accord-bg;
        }

        &--transcription {
            display: inline-block;
            line-height: $line-height;
            font-size: $font-size--text;
        }
    }

    &__content {
        margin-top: 0;
        background-color: transparent;
        z-index: 2;

        &--question {
            padding: 30px 30px 20px;
        }

        &--transcription {
            padding: 10px 20px 0 0;
        }
    }

    details {
        animation: appear .5s;

        summary > svg {
            position: absolute;
            z-index: 2;
            margin: auto;
            transform: rotate(0);
            transition: transform $duration $timing;
        }

        &[open] {
            height: auto;

            summary > svg {
                transform: rotate(-180deg);
            }
        }

        summary {
            &::-webkit-details-marker {
                display: none;

            }
        }
    }

    &--question {
        details {
            border-radius: $border-radius;
            border: $accord-details-border;
            overflow: hidden;

            summary > svg {
                right: 30px;
                top: 0;
                bottom: 0;
                margin: auto;
                @include size(40px);
                fill: $accord-svg-fill;
            }
        }
    }

    &--transcription {
        display: inline-flex;
        margin: 10px 0;

        details {
            summary > svg {
                @include size(26px);
                fill: $card--color;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .accordion__content--question {
        padding: 20px 20px 20px;
    }
    .accordion__title--question {
        padding: 20px 60px 20px 20px;
    }
    .accordion--question details summary>svg {
        right: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .accordion {
        &__title--question {
            @include font-size(19);
        }
    }

}
