//=================================================================================================
// Filter
//=================================================================================================
.filter {
    background-color: $filter-bg;

    &__container {
        @extend %container;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    // Title + Toggler button
    &__title {
        font-family: $btn-toggler-title-font-family;
        font-size: $btn-toggler-title-font-size;
        font-weight: $btn-toggler-title-font-weight;
        line-height: $btn-toggler-title-line-height;
        text-transform: $btn-toggler-title-text-transform;
        color: $btn-toggler-title-color;
    }
    &__icon {
        @include flex($alignItems: center);
        margin: 0 0 0 10px;
        background-color: $btn-toggler-bg;
        border: 1px $btn-border-style $btn-toggler-border-color;
        border-radius: $border-radius--round;
        transition: {
            property: transform, background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(20px);
            fill: $btn-toggler-color;
            transition: fill $duration $timing;
        }
    }
    &__toggler {
        display: flex;
        align-items: center;

        &.is-open {
            .filter__icon {
                transform: rotate(-180deg);
            }
        }

        &:hover, &:focus, &:active {
            .filter__icon {
                background-color: $btn-toggler-bg--hover;
                border-color: $btn-toggler-border-color--hover;
        
                svg {
                    fill: $btn-toggler-color--hover;
                }
            }
        }
    }

    &__options {
        //display: none;
        margin-top: 20px;
    }

    &__item[class*=col-] {
        padding-bottom: 20px;
    }

    &__action[class*=col-] {
        padding-bottom: 0;
    }

    // Container input (for select)
    &__input-container {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        // Animation rotate on svg select
        // .filter__input-svg {
        //     transition: fill $duration $timing, transform $duration $timing;
        // }
    }

    input.filter__input {
        min-height: 50px;
        padding: 15px; 
        background-color: $f-input-bg;
        border: $f-input-border-width $border-style $f-input-border-color !important;
        border-radius: $f-input-border-radius;
        font-size: $f-input-font-size;
        color: $f-input-color;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        // Fix disable style on IOS https://stackoverflow.com/questions/262158/disabled-input-text-color-on-ios
        &:disabled, &:disabled::placeholder {
            -webkit-text-fill-color: $f-input-color; /* 1. sets text fill to current `color` for safari */
            opacity: 1; /* 2. correct opacity on iOS */
        }

        &::placeholder {
            color: $f-input-color;
            -webkit-text-fill-color: $f-input-color;
            opacity: 1; // required on iOS
        }

        &:focus {
            border-color: $f-input-border-color--active !important;
        }

        // all
        &#keywordsFilter, &#subCategoriesInput, 
        // Documents
        &#monthsReleaseInput, &#yearsReleaseInput, 
        // customs filters
        &#filter_communes, 
        // Search
        &#postTypeInput, 
        // jobs
        &#filter_type_contrat, 
        &#filter_duree_hebdo, 
        &#filter_salaire, 
        &#filter_localisation, 
        &#filter_domaine, 
        &#filter_experience {
            padding-right: 45px;
        }

        &:not(#keywordsFilter):not(#mapKeywordsFilter):not(#startDateFilter):not(#endDateFilter) {
            pointer-events: none;
        }

        // Keywords input + button
        &#keywordsFilter {
            border-color: $f-inputKeyword-border-color !important;

            &:focus {
                border-color: $f-inputKeyword-border-color--active !important;
            }
        }

        &.selected {
            background-color: $f-input-bg--selected;
            border-color: $f-input-border-color--selected !important;
            color: $f-input-color--selected;

            &:disabled, &:disabled::placeholder {
                -webkit-text-fill-color: $f-input-color--selected; /* 1. sets text fill to current `color` for safari */
                opacity: 1; /* 2. correct opacity on iOS */
            }

            &::placeholder {
                color: $f-input-color--selected;
                -webkit-text-fill-color: $f-input-color--selected;
                opacity: 1; // required on iOS
            }

            // SVG icon
            & ~ .filter__input-svg {
                fill: $f-input-icon--selected;
            }

            // Close button Keywords input
            & ~ .filter__input-close {
                opacity: 1;
                pointer-events: inherit;
            }

            &#keywordsFilter {
                border-color: $f-inputKeyword-border-color--selected !important;
            }
        }
    }
    
    &__input-svg {
        @include absolute($right: 10px);
        @include size(30px);
        fill: $f-input-icon;
        transition: fill $duration $timing;
    }

    &__input-close {
        @include flex($alignItems: center, $justifyContent: center);
        @include absolute($top: 50%, $right: 10px);
        pointer-events: none;
        opacity: 0;
        @include size(30px);
        margin: 0;
        padding: 0;
        background-color: $f-Keyword-bg;
        border: $f-Keyword-close-border-width $btn-border-style $f-Keyword-border-color;
        border-radius: $btn-spe-border-radius;
        transform: translateY(-50%);
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(26px);
            fill: $f-Keyword-color;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $f-Keyword-bg--hover;
            border-color: $f-Keyword-border-color--hover;

            svg {
                fill: $f-Keyword-color--hover;
            }
        }
    }


    &__select-wrapper {
        padding-bottom: 0;
        position: relative;
        z-index: 1;

        &.is-closed {
            .filter-container {
                visibility: hidden;
                opacity: 0;
                height: 0;
                padding: 0;
            }
        }

        &.is-open {
            z-index: 2;
            border-radius: $f-input-border-radius $f-input-border-radius 0 0;

            .filter__input-container {
                z-index: 2;

                input.filter__input {
                    border-radius: $f-input-border-radius $f-input-border-radius 0 0;
                }

                .filter__input-svg {
                    transform: rotateX(-180deg);
                }
            }    

            .filter-container {
                visibility: visible;
                opacity: 1;
            }

            input.filter__input {
                background-color: $f-input-bg--active;
                border-color: $f-input-border-color--active !important;
                color: $f-input-color--active;

                &#keywordsFilter {
                    border-color: $f-inputKeyword-border-color--active;
                }

                &:disabled, &:disabled::placeholder {
                    -webkit-text-fill-color: $f-input-color--active; /* 1. sets text fill to current `color` for safari */
                    opacity: 1; /* 2. correct opacity on iOS */
                }

                &::placeholder {
                    color: $f-input-color--active;
                    -webkit-text-fill-color: $f-input-color--active;
                    opacity: 1; // required on iOS
                }

                & ~ .filter__input-svg {
                    fill: $f-input-icon--active;
                }
            }
        }

        &.date-picker {
            .filter__input-svg {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .filter-container {
        overflow: auto;
        position: absolute;
        width: 100%;
        max-height: 290px;
        padding: 15px 10px 0px;
        background-color: $f-input-container-bg;
        border: $f-input-border-width $border-style $f-input-border-color--active;
        border-radius: 0 0 $f-input-border-radius $f-input-border-radius;
        border-top: 0;

        ul {
            li {
                @include flex($direction: row-reverse, $alignItems: center, $justifyContent: space-between);
                padding: 0;
                margin-bottom: 10px;
                border-radius: $border-radius;

                &[data-level="2"] {
                    label::before {
                        content: "-";
                    }
                }
                &[data-level="3"] {
                    label::before {
                        content: "- -";
                    }
                }
                &[data-level="4"] {
                    label::before {
                        content: "- - -";
                    }
                }

                label {
                    width: 100%;
                    cursor: pointer;
                    margin: 0;
                    padding: 10px 35px 10px 10px;
                    background-color: $f-sub-input-bg;
                    border: $f-sub-input-border-width $border-style $f-sub-input-border-color;
                    border-radius: $f-sub-input-border-radius;
                    font-weight: $font-weight;
                    font-size: $f-input-font-size;
                    color: $f-sub-input-color;
                    transition: {
                        property: background-color, border-color, color;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                input {
                    position: absolute;
                    right: 25px;

                    &:checked {
                        & ~ label {
                            background-color: $f-sub-input-bg--checked;
                            border-color: $f-sub-input-border-color--checked;
                            color: $f-sub-input-color--checked;
                        }
                    }
                }

                &:hover, &:focus {
                    label {
                        color: $f-sub-input-color--checked;
                        background-color: $f-sub-input-bg--checked;
                        border-color: $f-sub-input-border-color--checked;
                    }
                }

                .filter__date-radio, .filter__input-radio {
                    display: none;
                }
            }
        }
    }

    &__indicator {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
        padding: 0 0 10px 0;
    }

    &__all-select {
        margin: 0;
    }

    .empty-selection {
        opacity: 0.45;
        pointer-events: none;
    }

    #sub-categories[data-disabled="disabled"] .select-box {
        opacity: 0.35;
        pointer-events: none;
    }
}
