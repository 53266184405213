.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: $color-white;
    padding-bottom: 80px;

    h2 {
        text-transform: uppercase;
    }
}

.container--home {
    @include flex($wrap: wrap);
    width: $large;
}

.home {
    h2 {
        font-size: $font-size--1;
        line-height: toRem(55);
        font-weight: $font-weight-bold;
        padding: 0;
        text-transform: none;
        margin: 0;

        &::before {
            display: none;
        }
    }

    &__button {
        height: 50px;
        font-size: $font-size--text;
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--pagination {
                @include absolute(0, 0, 0, 0);
                height: 100%;
                pointer-events: none;
            }
        }

        .slideshow {
            @include size(100%, 500px);

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            &__content {
                @include flex($direction: column);
                box-sizing: border-box;
                @include absolute($right: 30px, $bottom: 50px);
                @include size(500px, auto);
                padding: 20px;
                background-color: $color-bg-caption;
                color: $color-caption;
            }

            &__title {
                margin-bottom: 15px;
                font-size: $font-size--4;
                font-weight: $font-weight-bold;
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Pagination (bullet)
            &__pagination {
                @include absolute($bottom: 30px, $left: 0, $right: 0);
                @include flex($alignItems: center, $justifyContent: flex-end);
                margin: 0 auto;
                gap: 10px;
                height: 25px;
                width: fit-content;
                padding: 6px 10px;
                background-color: rgba(0, 0, 0, 0.40);
                border-radius: 5px;
                z-index: 2;
                pointer-events: auto;
                background-color: $color-white;

                .nav-swiper-autoplay {
                    @include size(12px);
                    padding: 0;
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    margin: 0;

                    &__icon {
                        @include size(12px);
                        fill: $color-dark;
                    }

                    &:hover,
                    &:focus {
                        .nav-swiper-autoplay__icon {
                            fill: $color-dark;
                        }
                    }
                }

                &__bullets {
                    @include flex($alignItems: center);
                    gap: 10px;

                    .swiper-pagination-bullet {
                        @include size(12px);
                        margin: 0 !important;
                        opacity: 1 !important;
                        background-color: $color-dark;
                        border-color: $color-dark;
                        border-radius: 30px;
                        transition: {
                            property: background-color, border-color;
                            duration: $duration;
                            timing-function: $timing;
                        }

                        &:hover,
                        &:focus, 
                        &-active {                    
                            background-color: $color-bg--transparent;
                            border: 1px solid $color-dark;
                        }
                    }
                }
            }

            .nav-swiper-autoplay {
                &--pause {
                    .nav-swiper-autoplay__icon {
                        &--pause {
                            display: none;
                        }
                        &--play {
                            display: block;
                        }
                    }
                }
                
                &--play {
                    .nav-swiper-autoplay__icon {
                        &--play {
                            display: none;
                        }
                        &--pause {
                            display: block;
                        }
                    }
                }
            }
        
        }
    }

    //======================================================================================================
    // Weather
    //======================================================================================================
    .weather {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 435px;
        left: 0;
        width: 180px;
        height: 70px;
        z-index: 3;
        background-color: $color-dark;
        border-radius: 0 35px 35px 0;
        padding: 15px 25px 15px 20px;
        overflow: hidden;
        transition: {
            property: height, border-radius;
            duration: $duration--second;
            timing-function: $timing;
        };
        
        &__header {
            @include flex($direction: row, $alignItems: center);
        }

        &__picto {
            width: 20px;
            height: 20px;
            margin-right: 10px;  
            fill: $color-white;
        }

        &__title {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            line-height: 1.42;
            color: $color-white;
            text-transform: uppercase;
        }

        &__content {
            opacity: 0;
            height: 0;
            transition: opacity $duration--second $timing;
            color: $color-white;
        }

        &:hover, &:focus {
            height: 300px;
            border-radius: 0 35px 35px 0;
            .weather {
                &__content {
                    opacity: 1;
                    height: fit-content;
                }
            }
        }

        &__tmp {
            font-size: $font-size--text-small;
            font-weight: $font-weight-medium;
            line-height: 1.42;
            color: $color-white;
            margin-top: 20px;
        }

        &__tide { 
            color: $color-white;
            font-size: toRem(12);
            line-height: 1.5;
            font-weight: $font-weight-light;
            margin-top: 20px;

            &--title {
                font-weight: $font-weight-medium;
            }
        }
        
    }

    //======================================================================================================
    // Searchbar home
    //======================================================================================================
    .home-search {
        .search-popup__content {
            position: absolute;
        top: 555px;
            width: 560px;
            margin: auto;
            left: 0;
            right: 0;
            z-index: 5;
        }

        .search-container {
            z-index: 5;
            input {
                background: $color-third--rgba;
                border-radius: $border-radius;
                border: none;
                padding: 0 20px;
                height: 55px;
                z-index: 2;

                &:focus {
                    background-color: $color-white;
                }
            }

            &-open {
                input {
                    background-color: $color-white;
                }
            }

            .search-svg {
                z-index: 3;
                width: auto;
                height: 50px;
                background-color: $color-bg--transparent;
                border-color: $color-bg--transparent;

                svg {
                    width: 40px;
                    height: 40px;
                    fill: $color-dark;
                }
            }
        }

        .searchResult {
            position: absolute;
            background: $color-white;
            top: 29px;
            padding-top: 25px;
            width: 100%;
            border-radius: 0 0 25px 25px;
            overflow: hidden;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
            z-index: 1;

            li {
                background-color: $color-bg--transparent;
                border-color: $color-second--rgba;

                &:first-child {
                    border-top: 1px solid $color-second--rgba;
                }

                a {
                    padding: 15px 110px 15px 24px;

                    &:hover, &:focus {
                        background-color: $color-light;

                        .resultCPT {
                            color: $color-dark;
                        }
                    }
                }
            }
        }
    }

    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        margin-top: 80px;
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        width: 100%;
        gap: 30px;


        &__item {
            @include size(210px, 140px);
            @include flex($direction: column, $alignItems: center, $justifyContent: space-between);
            padding: 30px 29px;
            border-radius: 5px;
            overflow: hidden;
            background-color: $color-light;
            position: relative;
            font-size: $font-size--text;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 10px;
                background-color: $color-main;
                z-index: 1;
                transition: {
                    property: width;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }

            svg {
                @include size(55px);

                circle {
                    fill: $color-second;
                    transition: {
                        property: fill;
                        duration: $duration--second;
                        timing-function: $timing;
                    }
                }
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                svg {
                    circle {
                        fill: $color-main;
                    }
                }
            }
        }
    }


    //======================================================================================================
    // news
    //======================================================================================================
    .news {
        margin-top: 80px;
        @include flex($direction: column);
        width: 100%;
        z-index: 2;

        &__content {
            @include flex($direction: row, $wrap: wrap, $justifyContent: space-between);
            gap: 30px;
            margin-top: 20px;
        }

        &__link {
            @include size(calc(50% - 15px), 300px);
            border-radius: $border-radius;
            overflow: hidden;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 10px;
                background-color: $color-main;
                z-index: 5;
                transition: {
                    property: width;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }


            &:hover, &:focus {
                &::before {
                    width: 100%;
                }
            }
        }

        &__image {
            @include size(100%);
            z-index: 1;

            img {
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }

            &--notimg {
                background: url($urlShort + '/posts-icons/post.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__infos {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
            z-index: 2;
            @include flex($direction: column, $justifyContent: flex-end);
            padding: 30px;

            &__category {
                background-color: $color-white;
                padding: 5px 12px;
                border-radius: 50px;
                width: fit-content;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }

            &__title {
                font-size: toRem(28);
                font-weight: $font-weight-bold;
                color: $color-white;
                margin: 20px 0 0;
            }

            &__intro {
                color: $color-white;
                margin-top: 5px;
            }
        }

        &__button {
            margin: 30px 0 0 auto;
            width: fit-content;

            svg {
                @include size(20px);
            }
        }
    }


    //======================================================================================================
    // Application
    //======================================================================================================
    .application {
        margin-top: 80px;
        background-color: $color-light;
        padding: 50px;
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        width: 100%;
        position: relative;
        z-index: 1;

        // &::before {
        //     content: '';
        //     @include size(485px, 385px);
        //     position: absolute;
        //     top: -245px;
        //     left: -245px;
        //     z-index: -1;
        //     background: url($urlSpriteImage + "theme-decos/forme.svg") center no-repeat;
        //     background-position: cover;
        //     pointer-events: none;
        // }

        &__text {
            width: 500px;
        }

        &__intro {
            margin-top: 10px;
        }

        &__link {
            @include size(300px, 140px);
            border-radius: 5px;
            border: $border-style 2px $color-white;
            @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
            gap: 5px;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            position: relative;
            overflow: hidden;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: $color-white;
                z-index: -1;
                transition: {
                    property: width;
                    duration: $duration--second;
                    timing-function: $timing;
                }
            }


            &:hover, &:focus {
                &::before {
                    width: 100%;
                }
            }
            
            svg {
                @include size(55px);
            }
        }
    }

    //======================================================================================================
    // Agenda
    //======================================================================================================
    .events {
        position: relative;
        margin-top: 100px;
        width: 100%;

        &::before {
            content: '';
            @include size(485px, 385px);
            position: absolute;
            top: -245px;
            left: -245px;
            z-index: -1;
            background: url($urlSpriteImage + "theme-decos/forme.svg") center no-repeat;
            background-position: cover;
            pointer-events: none;
        }

        h2 {
            margin-bottom: -45px;
            width: fit-content;
        }
        
        &__navigation {
            @include flex($direction: row);
            gap: 10px;
            margin-left: auto;
            width: fit-content;

            button {
                @include size(40px);
                @include flex($alignItems: center, $justifyContent: center);
                position: relative;
                border-radius: $border-radius--round;
                background-color: $color-second;
                border: 0;

                //cancel swiper's default style
                margin: 0;
                padding: 0;
                left: unset;
                right: unset;
                
                &::after {
                    display: none;
                }

                svg {
                    @include size(40px);
                    fill: $color-text;
                }

                &:hover, &:focus {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &__swiper {
            margin-top: 25px;
        }

        &__item {
            height: 360px;
            position: relative;
            border-radius: $border-radius;
            overflow: hidden;


            &:hover, &:focus {

                .events__date {
                    background-color: $color-second;
                }

                .events__info-title {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        &__image {
            @include size(100%);
            position: relative;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &--notimg {
                background: url($urlSpriteImage + '/events-icons/events.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__date {
            position: absolute;
            top: 30px;
            right: 0;
            font-family: $font-family--heading;
            font-size: toRem(18);
            font-weight: $font-weight-extrabold;
            text-transform: uppercase;
            color: $color-text;
            z-index: 4;
            background-color: $color-main;
            padding: 5px 15px;
            border-radius: 5px 0 0 5px;
            transition: {
                property: background-color;
                duration: $duration;
                timing-function: $timing;
            }
        } 

        &__info-title {
            @include absolute($top: 0, $left: 0);
            padding: 30px;
            @include size(100%);
            box-sizing: border-box;
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-end);
            font-size: toRem(28);
            margin: 0;
            font-weight: $font-weight-bold;
            color: $color-white;
            z-index: 3;           
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.195) 30%, rgba(0, 0, 0, 0.65) 100%);

            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border-radius: inherit;
                transition: opacity $duration $timing;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.255) 30%, rgba(0, 0, 0, 0.85) 100%);
            }

        }

        &__buttons {
            width: fit-content;
            margin-top: 30px;
            margin-left: auto;
            @include flex($direction: row, $justifyContent: space-between);
            gap: 20px;
        }

        &__prop {
            background-color: $color-second;
            color: $color-text;
            border: 0;
            margin: 0;

            svg {
                @include size(20px);
                fill: $color-text;
            }

            &:hover, &:focus {
                background-color: $color-light;
            }
        }

        &__button {
            margin: 0;

            svg {
                @include size(20px);
            }
        }
    }



    
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        padding-bottom: 100px;
    }

    .home-search .search-popup__content {
        top: 495px;
    }

    .home-slideshow {
        .slideshow {
            height: 400px;

            &__pagination {
                bottom: 20px;
            }
        }
    }



    .access {
        gap: 20px;

        &__item {
            @include size(172px, 140px);
            font-size: $font-size--text-small;
            padding: 30px 19px;
        }
    }
    
    .news {
        &__content {
            gap: 20px;
        }

        &__link {
            @include size(calc(50% - 10px), 250px);
        }

        &__infos {
            padding: 25px;

            &__title {
                font-size: toRem(25);
            }

            &__intro {
                font-size: $font-size--text-small;
            }
        }
    }

    .application {
        margin-top: 100px;
        padding: 40px;
        align-items: center;
    }

    .events {
        &__item {
            height: 290px;
        }

        &__date {
            top: 20px;
        }

        &__info-title {
            font-size: toRem(25);
            padding: 20px;
        }

        &__buttons {
            margin-top: 20px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;

        .slideshow {
            height: 270px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }

    .weather {
        display: none;   
    }

    .home-search {
        display: none;
    }

    .access {
        flex-wrap: wrap;
        justify-content: center;

        &__item {
            @include size(210px, 140px);
        }
    }

    .application {
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
    }
}


// 640
@media screen and (max-width: $small) {

    .home-slideshow {
        .slideshow {
            height: 200px;
        }
    }

    .access {
        gap: 10px;

        &__item {
            @include size(145px, 120px);
            padding: 20px 15px;
            font-size: toRem(12);
        }
    }


    .news {
        &__content {
            flex-direction: column;
        }

        &__link {
            @include size(100%, 250px);
        }

        &__infos {
            &__title {
                font-size: toRem(22);
            }
        }
    }

    .application {
        width: 100vw;
        padding: 40px calc(5% + 10px);
        margin: 80px calc(-5% - 15px) 0;

        // &::before {
        //     display: none;
        // }

        &__text {
            width: 100%;
        }
    }

    .events {

        &::before {
            display: none;
        }

        &__item {
            height: 270px;
        }

        &__buttons {
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: space-between);
            gap: 20px;
            margin: 30px 0 0 0;
        }
    }
}

}

