.breadcrumb {
    background-color: $color-light;

    ul {
        display: block;
        min-height: 55px;
        padding: 30px 0 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text;
        line-height: 1.5;

        a {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: {
               property: color, text-decoration-color;
               duration: $duration;
               timing-function: $timing;
            }
            
            &:hover, &:focus {
               text-decoration-color: $color-text;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-text;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
