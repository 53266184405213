header {
    position: relative;
}

.header {

    &__container {
        @include flex($direction: row, $alignItems: center);
        padding-top: 45px;
        padding-bottom: 45px;
    }

    @keyframes headerFixed {
        from {
            top: -200px;
        }
        to {
            top: 0;
        }
    }

    &__home-btn {
        @include size(40px);
        border-radius: $border-radius--round;
        background-color: $color-third;
        @include flex( $alignItems: center, $justifyContent: center);
            transition: {
            property: background-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            background-color: $color-second;
        }

        svg {
            @include size(100%);
            fill: $color-dark;
            transition: {
                property: fill;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 85px;
        z-index: 10;
        animation: .5s headerFixed;
        box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.08);
        border-radius:  0 0 30px 30px;


        & ~ .content-wrapper {
            margin-top: 285px;
        }

        > .container {
            display: none;
        }
        
        .nav-main {
            height: 85px;
        }

        .menu__item--home-btn {
            @include flex($alignItems: center, $justifyContent: center);
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(280px, 80px);
    margin-right: auto;
}


//======================================================================================================
// Reseaux
//======================================================================================================
.reseaux {
    width: fit-content;
    padding-right: 65px;
    margin-right: 25px;
    position: relative;

    &::before {
        content: "";
        @include absolute($top: calc(50% - 2px), $right: 0);
        width: 40px;
        height: 4px;
        border-radius: 50px;
        background-color: $color-light;

    }

    &__list {
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        gap: 10px;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px, 40px);
        border-radius: $border-radius--round;
        overflow: hidden;

        a {
            @include size(100%);
            background-color: $color-second;
            transition: {
                property: background-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                background-color: $color-dark;

                .reseaux__picto {
                    fill: $color-white
                }
            }
        }
    }

    &__picto {
        @include size(100%);
        fill: $color-dark;
        transition: {
            property: fill;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    z-index: 11;
    pointer-events: none;
    width: fit-content;

    &__content {
        width: fit-content;
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        gap: 10px;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        padding: 0;
        background-color: $color-dark;
        border: 0;
        margin: 0;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            @include size(100%);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-main;
            svg {
                fill: $color-dark;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }

    &__translate {
        &__bzh {
            order: 1;
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            color: $color-gray;
            line-height: toRem(18);

            span {
                font-size: toRem(12);
                font-weight: $font-weight-light;
                font-style: italic;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
// @media screen and (max-width: $large) {
// }


// 960
@media screen and (max-width: $medium) {


    // Identity
    .identity {
        display: flex;
        @include size(210px, 60px);

        h1 {
            margin: 0;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .reseaux {
        padding: 0;
        margin: 0;

        &::before {
            display: none;
        }
    }

    // Tools
    .tools {
        position: fixed;
        bottom: 20px;
        right: calc(5% + 10px);

        &__container {
            margin: 0;
        }

        &__item {
            background-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }
    
    .js-open-menu .tools {
        z-index: 9;
    }
}


// 640
@media screen and (max-width: $small) {

    .header {
        &__container {
            margin: 0;
            padding: 0 0 45px;
            max-width: 100%;
            width: 100%;
            flex-direction: column-reverse;
            gap: 45px;
            align-items: center;
            justify-content: space-between;
        }
    }

    .identity {
        margin: 0;
    }

    .reseaux {
        width: 100%;
        background-color: $color-second;
        padding: 10px 0;

        &__list {
            margin: 0 auto;
            width: fit-content;
        }

        &__item {
            a {
                background-color: $color-white;
            }
        }
    }
}
