//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .listing {
        &__line {
            padding: 15px 0;

            > svg {
                margin: 0 5px 0 -5px;
                width: 35px;
            }
        }

        &-contact {
            @include flex($wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 15px 30px;

            li:not(:first-child) {
                margin-top: 0;
            }

            &__mail {
                align-items: center;
            }

            &__tel {
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }

        &--accessibility {
            padding: 25px 0;
            column-gap: 50px;
            
            .listing__line--accessibility {
                padding: 0;
                margin: 0;

                &:not(:first-child) {
                    svg {
                        width: 45px;
                    }
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    // .listing--accessibility .listing__line--accessibility:not(:first-child),
    // .event-v2 .listing--accessibility .listing__line--accessibility:not(:first-child) {
    //     width: 100%;
    // }

}