.cover {
    position: relative;
    background-color: $color-light;
    margin-bottom: 40px;    

    &--404, &--search {
        .cover__informations {
            padding-top: 30px;
        }
    }

    &__image-wrapper {
        width: 100%;
        height: 500px;
        background-color: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        padding: 15px 0 40px;
    }

    &__title {
        color: $color-text;
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 15px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 270px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 200px;
        }
    }

}
