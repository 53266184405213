.nav-main {
    position: relative;
    z-index: 10;
    background-color: $color-dark;
    height: 115px;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        height: 100%;
    }

    // Main menu
    .menu {
        @include flex($justifyContent: space-between, $alignItems: center);
        width: 100%;
        height: 100%;

        &__item {
            height: 100%;

            &--home-btn {
                display: none;
            }

            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            background-color: $color-bg--transparent;
            color: $color-white;
            height: 100%;
            font-family: $font-family--heading;
            text-transform: uppercase;
            font-size: $font-size--5;
            line-height: toRem(25);
            font-weight: $font-weight-bold;
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            &::before {
                content: "";
                @include absolute($bottom: 0, $left: 50%);
                transform: translateX(-50%);
                z-index: 0;
                @include size(30px, 15px);
                clip-path: polygon(50% 0, 0% 100%, 100% 100%);
                background-color: $color-second;
                opacity: 0;
                transition: {
                    property: opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
    
            &:hover, &:focus {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        position: absolute;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: 60px 15px;
        background-color: $color-second;
        box-shadow: $shadow;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            padding: 15px 30px 15px 0;
            color: $color-dark;
            font-weight: $font-weight-bold;            

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: 0;
                width: 0;
                height: 3px;
                background-color: $color-text;
                transition: width $duration $timing;
            }

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-dark;
                transition: opacity $duration $timing;
                opacity: 0.5;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                &::after {
                    opacity: 1;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
        
        &__close {
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            height: 30px;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &__icon {
                display: block;
                @include size(30px);
                background-color: $color-white;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                fill: $color-dark;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill, border-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-dark;
                    border-color: $color-dark;
                    fill: $color-white;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        .menu__link {
            font-size: $font-size--text;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100vw;
        height: 80px;
        padding: 20px 0;
        border-top: $border-style 1px $color-light;

        > .container:first-child {
            margin: 0 auto;
            padding: 0 10px;
            max-width: 90%;
            justify-content: flex-start;
        }

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-second;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 10px;
            margin: 0;
            padding: 10px 20px;
            height: 40px;
            background-color: $color-white;
            border: 0;
            border-radius: 50px;
            font-size: $font-size--text;
            font-weight: $font-weight-bold; 
            color: $color-dark;
            text-transform: uppercase;
            cursor: pointer;

            &:hover, &:focus {

                .nav-main__icon {
                   fill: $color-dark;
                }
            }

            // Button icon (Burger menu responsive - Open menu)
            .nav-main__icon {
                fill:$color-dark;
                display: flex;
                @include size(20px);
            }
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: $color-white;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-dark;
            }

            &:hover,
            &:focus {
                background-color: $color-dark;
                border-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                height: fit-content;

                &--home-btn {
                    display: none;

                }
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-dark;
                        transition: none;
                    }

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-dark;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-dark;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-dark;
                transition: none;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-dark;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-second;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: $color-white;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                order: 1;
                transition: {
                    property: background-color, border-color;
                    duration: $duration;
                    timing-function: $timing;
                }

                &:hover,
                &:focus {
                    background-color: $color-dark;
                    border-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }

                &__mobile {
                    display: block;
                    width: 20px;
                    height: 20px;
                    fill: $color-dark;
                    transform: rotate(-180deg);
                    transition: {
                        property: fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-dark;
                padding: 10px 30px 10px 0;

                &::before, &::after {
                    display: none;
                }
            }
        }
    }

}
